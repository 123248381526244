<script setup>
import ButtonLink from '@/Components/Button/Link.vue';
import { usePage } from '@inertiajs/vue3';
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';

defineProps({
    fullWidth: Boolean,
});

const currentPage = usePage().props.route.current;
const route = inject('route');
const { t } = useI18n();

const isEmployerPage = computed(() => !!currentPage.includes(route('public.pages.show', { page: t('employers') })));
</script>

<template>
    <ButtonLink
        :href="route('public.pages.show', { page: $t('employers') })"
        :class="{ 'opacity-50 pointer-events-none': isEmployerPage, 'w-full': fullWidth }"
        class="justify-center"
    >
        <div class="gap-4 flex-center">
            <div class="text-base leading-none">
                {{ $t('For employers') }}
            </div>
        </div>
    </ButtonLink>

    <ButtonLink
        pinkTransparent
        :href="route('home')"
        :class="{ 'opacity-50 pointer-events-none': !isEmployerPage, 'w-full': fullWidth }"
        class="justify-center"
    >
        <div class="gap-4 flex-center">
            <div class="text-base leading-none">
                {{ $t("For Fl@xr's") }}
            </div>
        </div>
    </ButtonLink>
</template>
